<template>
  <div>
    <h4>Licencia por maternidad</h4>
    <div class="cols2">
<!--      <div class="form-group">-->
<!--        <label class="form-label">Tipo de novedad *</label>-->
<!--        <select-->
<!--          id="tipo_novedad"-->
<!--          class="form-control readonly"-->
<!--          disabled-->
<!--          v-model="percepcionMaternidad.tipo_novedad"-->
<!--        >-->
<!--          <option :value="null">Seleccione una opción</option>-->
<!--          <option :value="TipoNovedadConstants.ALTA">{{ TipoNovedadConstants.ALTA }}</option>-->
<!--          <option :value="TipoNovedadConstants.BAJA">{{ TipoNovedadConstants.BAJA }}</option>-->
<!--        </select>-->
<!--      </div>-->
      <pd-input
        v-model="percepcionMaternidad.maternidad.fecha_notificacion"
        id="fecha_notificacion"
        label="Fecha de notificacion al empleador de la declaración de opción de licencia *"
        type="date"
        readonly
      />
      <pd-input
          v-model="percepcionMaternidad.maternidad.dias_previos_fpp"
          id="dias_previos_fpp"
          label="Cantidad de días corridos antes de la F.P.P."
          type="number"
          readonly
          min="10"
          max="45"
      />
    </div>
    <div class="cols2">
      <pd-input
        v-model="percepcionMaternidad.maternidad.fecha_inicio"
        id="fecha_inicio"
        label="Fecha de inicio de licencia por maternidad *"
        type="date"
        readonly
      />
      <pd-input
        v-model="percepcionMaternidad.maternidad.fecha_fin"
        id="fecha_fin"
        label="Fecha de fin de licencia por maternidad *"
        type="date"
        readonly
      />
      <pd-input
          v-model="fechaReincorporacion"
          id="fecha_reincorporacion"
          label="Fecha de reincorporacion"
          type="date"
          readonly
      />
    </div>
  </div>
</template>
<script>
import {TipoParentezcoConstants} from "../constants/tipoParentezco";
import axios from "axios";
import FileLink from "@/components/elements/FileLink.vue";
import {TipoNovedadConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoNovedad";
import {
  TipoLicenciaMaternidadConstants
} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoLicenciaMaternidad";
import ShowPercepcionMaternidadCertificado
  from "@/modules/percepcionesAsignacionFamiliar/components/ShowPercepcionMaternidadCertificado.vue";
import {DateTime} from "luxon";

export default {
  name: "ShowPercepcionMaternidadMaternidad",
  components: {ShowPercepcionMaternidadCertificado, FileLink},
  computed: {
    TipoLicenciaMaternidadConstants() {
      return TipoLicenciaMaternidadConstants
    },
    TipoNovedadConstants() {
      return TipoNovedadConstants
    },
    TipoParentezcoConstants() {
      return TipoParentezcoConstants
    },
    acreditacion_conyuge() {
      return `${axios.defaults.baseURL}/${this.percepcionMaternidad.acreditacion_conyuge}`
    },
    fechaReincorporacion(){
      if(this.percepcionMaternidad?.maternidad?.fecha_fin){
        return DateTime.fromFormat(this.percepcionMaternidad.maternidad.fecha_fin, 'yyyy-LL-dd').plus({days: 1}).toFormat('yyyy-LL-dd')
      }
      return null
    },
  },
  props: {
    percepcionMaternidad: {
      type:  Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
