<template>
  <div>
    <p class="mt-2"><strong>Tipo de percepción:</strong> {{ percepcionMaternidad.tipo_percepcion }}</p>
    <p><strong>Tipo de novedad:</strong> {{ percepcionMaternidad.tipo_novedad }}</p>
    <ShowPercepcionMaternidadMaternidad
        v-if="percepcionMaternidad.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD"
        :percepcion-maternidad="percepcionMaternidad"
    />
    <ShowPercepcionMaternidadDown
        v-if="percepcionMaternidad.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD_DOWN"
        :percepcion-maternidad="percepcionMaternidad"
    />
    <ShowPercepcionMaternidadCertificado
        v-if="
        percepcionMaternidad.certificado_medico &&
        (
          percepcionMaternidad.tipo_percepcion === TipoPercepcionConstants.MATERNIDAD ||
          percepcionMaternidad.tipo_percepcion === TipoPercepcionConstants.PRENATAL
        )
      "
        :certificado-medico="percepcionMaternidad.certificado_medico"
    />
  </div>
</template>
<script>
import {TipoPercepcionConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoPercepcion";
import ShowPercepcionMaternidadMaternidad
  from "@/modules/percepcionesAsignacionFamiliar/components/ShowPercepcionMaternidadMaternidad.vue";
import FileLink from "@/components/elements/FileLink.vue";
import {TipoParentezcoConstants} from "@/modules/percepcionesAsignacionFamiliar/constants/tipoParentezco";
import axios from "axios";
import ShowPercepcionMaternidadCertificado
  from "@/modules/percepcionesAsignacionFamiliar/components/ShowPercepcionMaternidadCertificado.vue";
import ShowPercepcionMaternidadDown
  from "@/modules/percepcionesAsignacionFamiliar/components/ShowPercepcionMaternidadDown.vue";

export default {
  name: "ShowPercepcionMaternidad",
  components: {
    ShowPercepcionMaternidadDown,
    ShowPercepcionMaternidadCertificado, FileLink, ShowPercepcionMaternidadMaternidad
  },
  computed: {
    TipoPercepcionConstants() {
      return TipoPercepcionConstants
    },
    TipoParentezcoConstants() {
      return TipoParentezcoConstants
    },
    acreditacion_conyuge() {
      return `${axios.defaults.baseURL}/${this.percepcionMaternidad.acreditacion_conyuge}`
    }
  },
  props: {
    percepcionMaternidad: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
