<template>
  <div class="mt-2">
    <p>Datos de certificado médico y médico certificante</p>
    <div class="cols2">
      <FileLink text="Ver certificado médico" :file-path="certificadoMedico.certificado"/>
    </div>
  </div>
</template>
<script>
import {TipoFechaPartoConstants} from "../constants/tipoFechaParto";
import FileLink from "@/components/elements/FileLink.vue";

export default {
  name: "ShowPercepcionMaternidadCertificado",
  components: {FileLink},
  computed: {
    TipoFechaPartoConstants() {
      return TipoFechaPartoConstants
    }
  },
  props:{
    certificadoMedico: {
      type:  Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
